/**
 * .products-intro-wrapper.
 */

.products-intro-wrapper {

    /**
     * .products-intro-container.
     */

    .products-intro-container {

        /**
         * Padding.
         */

        padding-bottom: 17rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Padding.
             */

            padding-bottom: 12rem;

        }

    }

}