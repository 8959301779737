/**
 * .about-intro-wrapper.
 */

.about-intro-wrapper {

    /**
     * .about-intro-container.
     */

    .about-intro-container {

        /**
         * Padding.
         */

        padding-bottom: 0rem;

    }

}