/**
 * .home-stats-wrapper.
 */

.home-stats-wrapper {

    /**
     * Background.
     */

    background-color: $brand-five;

    /**
     * Overflow.
     */
    
    overflow: hidden;

    /**
     * Position.
     */

    position: relative;

    /**
     * Z-index.
     */

    z-index: 5;

    /**
     * .image-container.
     */

    .image-container {

        /**
         * Position related.
         */

        position: absolute;
        top: 0;
        left: 0;

        /**
         * Sizing.
         */

        height: 100%;
        width: 43%;

        /**
         * Background.
         */

        background-position: center;
        background-size: cover;

        /**
         * @include.
         */

        @include breakpoint($whilst-fluid) {

            background-image: none !important;

        }

    }

    /**
     * .home-stats-container.
     */

    .home-stats-container {
   
        /**
         * Padding.
         */

        padding-top: 5rem;
        padding-bottom: 6rem + 14rem;

        /**
         * @include.
         */

        @include breakpoint($whilst-fluid) {

            padding-top: 5rem;
            padding-left: 3rem;
            padding-bottom: 4rem + 12rem;

        }

        /**
         * Font related.
         */

        color: white;

        /**
         * .heading.
         */
    
        .heading {

            /**
             * Font related.
             */

            letter-spacing: 0.4rem;
            font-weight: bold;

        }

        /**
         * .stat-list.
         */

        .stat-list {

            /**
             * Padding.
             */

            padding-top: 2.5rem;

            /**
             * li.
             */

            li {

                /**
                 * Margin.
                 */

                margin-bottom: 2.5rem;

                /**
                 * p.number-heading.
                 */

                p.number-heading {

                    /**
                     * Font related.
                     */

                    font-size: 0.8rem;
                    line-height: 1.0rem;

                    /**
                     * Margin.
                     */

                    margin-bottom: 0.3rem;

                }

                /**
                 * p.number.
                 */

                p.number {

                    /**
                     * Font related.
                     */

                    font-size: 3.6rem;
                    font-weight: bold;
                    line-height: 4rem;                   

                    /**
                     * @include.
                     */

                    @include breakpoint($up-to-ipad-exclusive) {

                        font-size: 3rem;
                        line-height: 3.6rem; 

                    }

                }

                /**
                 * span.suffix.
                 */

                span.suffix {

                    /**
                     * Padding.
                     */

                    padding-left: 0.5rem;

                }

            }

        }

    }

}