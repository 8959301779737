/**
 * .about-infoblock-wrapper.
 */

.about-infoblock-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Background.
     */

    background-position: center;
    background-size: cover;

    /**
     * Padding.
     */

    padding-bottom: 31rem;
    padding-top: 6rem;

    /**
     * @include.
     */

    @include breakpoint($whilst-fluid) {

        /**
         * Background.
         */

        background-image: none !important;
        background-color: $brand-one;

        /**
         * Padding.
         */

        padding-bottom: 12rem;

    }

    /**
     * .razor-edge.
     */

    .razor-edge {

        @include razor-edge-top-for-image-masking(
            $brand-six
        );

    }

    /**
     * .about-infoblock-container.
     */

    .about-infoblock-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Z-index.
         */

        z-index: 10;

        /**
         * Font related.
         */

        color: white;

        /**
         * Margin.
         */
    
        margin-top: -5rem;

        /**
         * Padding.
         */

        padding-left: 6rem;
        padding-right: 6rem;
        padding-bottom: 5rem;

        /**
         * @include.
         */

        @include breakpoint($whilst-fluid) {

            padding-left: 1rem;
            padding-right: 1rem;

        }

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            $brand-one,
            50%,
            12deg,
            0,
            50%
        );

        /**
         * h2.
         */
    
        h2 {

            /**
             * Padding.
             */

            padding-top: 13rem;

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                /**
                 * Padding.
                 */

                padding-top: 9rem;

            }

            /**
             * Margin.
             */

            margin-bottom: 3rem;

        }

    }

}