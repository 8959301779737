/** 
 * .page-body-wrapper.
 */

.page-body-wrapper {

    /**
     * .page-body-container.
     */

    .page-body-container {

        /**
         * Padding.
         */

        padding-top: 6rem;
        padding-bottom: 24rem;

        /**
         * Font related.
         */

        color: $brand-two;

        /**
         * p.
         */

        p {

            /**
             * a.
             */

            a {

                color: $brand-two;
                text-decoration: underline;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-one;

                }

            }

        }

        /**
         * h1.
         */

        h1 {

            /**
             * Padding.
             */

            padding-bottom: 2rem;

        }

        /**
         * h2.
         */

        h2 {

            /**
             * Font related.
             */

            color: $brand-two;
            letter-spacing: 0;
            font-size: 2.2rem;

            /**
             * Margin.
             */

            margin-bottom: 1rem;

        }

        /**
         * h3.
         */

        h3 {

            /**
             * Font related.
             */

            color: $brand-two;
            font-size: 1.6rem;

            /**
             * Margin.
             */

            margin-bottom: 1rem;

        }

        h4 {

            /**
             * Font related.
             */

            color: $brand-two;
            font-size: 1.3rem;

            /**
             * Margin.
             */

            margin-bottom: 1rem;

        }

        /**
         * ul.
         */

        ul {

            /**
             * Margin.
             */

            margin-left: 1.25rem;
            margin-bottom: 1.5rem;

            /**
             * li.
             */

            li {

                /**
                 * List style.
                 */

                list-style: disc;

                /**
                 * Margin.
                 */

                margin-bottom: 0.5rem;

                /**
                 * Font related.
                 */

                font-size: 1.125rem;
                line-height: 1.7rem;

            }

        }

    }

}