/**
 * .covid-bar.
 */

.covid-bar {

    /**
     * 
     */

    background-color: $brand-five;

    /**
     * Sizing.
     */

    width: 100%;

    /**
     * Padding.
     */

    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Background.
         */

        background-color: $brand-two;

    }

    /**
     * a.
     */

    a {

        /**
         * Display.
         */
    
        display: block;

        /**
         * Font related.
         */

        text-decoration: none;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            font-size: 0.9rem;
            color: $brand-four;

            /**
             * Margin.
             */

            margin: 0;

        }

    }

}

/**
 * header.header.
 */

header.header {

    background-color: white;

    /**
     * Display.
     */

    display: flex;

    /**
     * Flex.
     */

    align-items: center;

    /**
     * Z-index.
     */

    z-index: 20;

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    left: 0;

    /**
     * Sizing.
     */

    width: 100%;
    height: 6rem;
    
    /**
     * Drop shadow.
     */

    -webkit-box-shadow: 2px 10px 8px -7px rgba(0,0,0,0.1);
    -moz-box-shadow: 2px 10px 8px -7px rgba(0,0,0,0.1);
    box-shadow: 2px 10px 8px -7px rgba(0,0,0,0.1);

    /**
     * .logo.
     */

    .logo {

        width: 5rem;

    }

    /**
     * .actions.
     */

    .actions {

        /**
         * Display.
         */

        display: flex;

        /**
         * 
         */

        align-items: center;

        /**
         * Z-index.
         */

        z-index: 10;

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: 0;

        /**
         * Sizing.
         */

        height: 100%;

        /**
         * .hamburger-button-wrapper.
         */

        .hamburger-button-wrapper {

            /**
             * Margin.
             */

            margin-right: 3rem;

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                margin-right: 1rem;

            }
            
            /**
             * .hamburger-button.
             */

            .hamburger-button {

                /**
                 * Background.
                 */

                background-color: white;

                /**
                 * Padding.
                 */

                padding: 0.8rem 0.5rem 0.8rem 0.5rem;

                /**
                 * &:hover.
                 */

                &:hover {

                    svg {

                        path {
        
                            fill: $brand-one;
        
                        }
        
                    }

                }

            }



        }

        /**
         * .hamburger-button-wrapper.
         */

        .hamburger-button-wrapper {

            /**
             * Display.
             */
        
            display: inline-block;

            /**
             * .hamburger-button.
             */

            .hamburger-button {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * svg.
                 */

                svg {

                    width: 2rem;

                }

            }

        }

        /**
         * .contact-button.
         */

        .contact-button {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            align-items: center;
            justify-content: center;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Sizing.
             */
        
            height: 100%;
            width: 8rem;

            /**
             * span.
             */

            span {

                /**
                 * Font related.
                 */

                color: white;
                font-weight: bold;

                /**
                 * Display.
                 */

                display: inline-block;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Font related.
                 */

                text-decoration: none;

            }

        }

    }

}