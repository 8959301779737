// $up-to-ipad: max-width 768px;
// $up-from-ipad: min-width 769px;

// $up-to-desktop: max-width 999px;
// $up-to-mobile: max-width 767px;

// $up-from-desktop: min-width 1000px;

$shallower-angle: min-width 1200px;


/**
 * 
 */

$up-to-ipad-exclusive: max-width 767px;
$up-to-ipad-inclusive: max-width 768px;

$up-to-desktop-exclusive: max-width 991px;

$whilst-fluid: max-width 575px;