/**
 * .ourreach-map-wrapper.
 */

.ourreach-map-wrapper {

    /**
     * Background.
     */


    background-color: $brand-six;

    /**
     * .ourreach-map-container.
     */

    .ourreach-map-container {
        
        /**
         * .heading.
         */

        .heading {

            /**
             * Margin.
             */

            margin-bottom: 3rem;

        }

    }

}

/**
 * .map-wrapper.
 */

.map-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Background.
     */

    background-color: $brand-six;

    /**
     * Padding.
     */

    padding-bottom: 18rem;
    padding-left: 5rem;
    padding-right: 5rem;

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * Padding.
         */

        padding-bottom: 12rem;

    }

    /**
     * Force all colors grey.
     */

    svg {

        /**
         * path.
         */

        path {

            /**
             * Fill.
             */

            fill: #9e9e9e;

        }

    }

    /**
     * 
     */

    .label {
        
        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Background.
         */

        background-color: #FFFFFF;
        
        /**
         * Font related.
         */

        font-size: 1.8rem;
        color: #272424;

        /**
         * Padding.
         */

        padding: 0.5rem 1rem 0.5rem 1rem;

        /**
         * Position related.
         */

        position: absolute;
        top: 0;
        left: 0;

        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-50%);

        /**
         * Transition.
         */

        transition: opacity 0.25s ease;

        /**
         * Pointer events.
         */

        pointer-events: none;

        /**
         * Box shadow.
         */

        box-shadow: 4px 4px rgba(39, 36, 36, 0.1);

    }

}

