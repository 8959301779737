/**
 * .navigation-flyout-wrapper.
 */

.navigation-flyout-wrapper {

    /**
     * Display.
     */

    opacity: 0;

    /**
     * 
     */

    pointer-events: none;

    /**
     * .hiding.
     */

    &.hiding {

        opacity: 1;

    }

    /**
     * &.show.
     */

    &.show {

        /**
         * Background.
         */

        background: rgba(#272424, 0.95);

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer events.
         */

        pointer-events: all;

        /**
         * .navigation-flyout-container.
         */

        .navigation-flyout-container {

            right: 0;

        }
        
        .logo {

            opacity: 1;

        }

    }

    /**
     * .logo.
     */

    .logo {

        /**
         * Sizing.
         */

        width: 15rem;

        /**
         * Position related.
         */

        position: absolute;
        top: 50%;
        left: 25%;

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Transition.
         */

        transition: opacity 0.3s ease-out;

        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-50%);

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            display: none;

        }

    }

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    right: 0;   

    /**
     * Sizing.
     */

    width: 100%;
    height: 100vh;

    /**
     * Background.
     */

    background: rgba(#272424, 0);

    /**
     * 
     */

    transition: background 0.3s ease-out;

    /**
     * Font related.
     */

    color: white;

    /**
     * Z-index.
     */

    z-index: 35;

    /**
     * .close-button.
     */

    .close-button {

        /**
         * Background.
         */

        background-color: $brand-one;

        /**
         * Display.
         */

        display: block;

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: 0;

        /**
         * Z-Index.
         */

        z-index: 10;

        /**
         * Sizing.
         */

        width: 6rem;
        height: 6rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Sizing.
             */

            width: 3rem;
            height: 3rem;

        }

        /**
         * &:hover.
         */

        &:hover {

            background-color: $brand-two;

        }

        /**
         * .close-icon.
         */

        .close-icon {

            /**
             * Sizing.
             */

            width: 1.5rem;

            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            left: 50%;

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

        }

    }

    /**
     * .navigation-flyout-container.
     */

    .navigation-flyout-container {

        /**
         * Background.
         */

        background-color: $brand-one;

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: -100%;

        /**
         * 
         */

        transition: right 0.4s ease-out;

        /**
         * Z-Index.
         */

        z-index: 9;

        /**
         * Sizing.
         */

        width: 50%;
        height: 100%;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Position related.
             */

            top: 0%;

            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;

        }

        /**
         * .menu-primary-menu-container.
         */

        .menu-primary-menu-container {

            /**
             * Position related.
             */

            position: absolute;
            top: 50%;
            left: 3rem;

            /**
             * @include.
             */

            @include breakpoint(min-width 1150px) {

                left: 5rem;

            }

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                left: 2rem;

            }
            
            /**
             * Transform.
             */

            transform: translateY(-50%);

            /**
             * ul.
             */

            ul {

                /**
                 * li.
                 */

                li {

                    margin-bottom: 1.25rem;

                    /**
                     * &.current_page_item, &.active.
                     */

                    &.current_page_item, &.active {

                        a {

                            font-weight: bold;
                            color: $brand-two;

                            &:hover {

                                color: $brand-two;

                            }
                        }

                    }

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        font-size: 3rem;
                        line-height: 3.5rem;
                        color: white;
                        text-decoration: none;

                        /**
                         * &:hover. 
                         */

                        &:hover {

                            color: $brand-two;

                        }

                    }

                }

            }

        }

    }

}