/**
 * .fourzerofour-wrapper.
 */

.fourzerofour-wrapper {

    /**
     * .fourzerofour-container.
     */

    .fourzerofour-container {

        /**
         * Padding.
         */
    
        padding-top: 10rem;

        /**
         * Sizing.
         */

        min-height: 70rem;

        /**
         * Font related.
         */

        color: $brand-two;

    }

}