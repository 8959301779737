/**
 * 
 */

$go-fluid-width: max-width 600px;

/**
 * .contact-flyout-wrapper.
 */

.contact-flyout-wrapper {

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * Pointer events.
     */

    pointer-events: none;

    /**
     * @include.
     */

    @include breakpoint($go-fluid-width) {

        /**
         * 
         */

        overflow: scroll;

    }

    /**
     * &.show.
     */

    &.show {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer events.
         */

        pointer-events: all;

        /**
         * .contact-flyout-container.
         */

        .contact-flyout-container {

            /**
             * Transform.
             */

            transform: translateX(0);

        }

    }

    /**
     * &.hiding.
     */

    &.hiding {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * .contact-flyout-container.
         */

        .contact-flyout-container {

            /**
             * Transition.
             */

            transition: transform 0.2s ease-in;

        }

    }

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    right: 0;   

    /**
     * Sizing.
     */

    width: 100%;
    height: 100vh;

    /**
     * Z-index.
     */

    z-index: 35;

    /**
     * .close-button.
     */

    .close-button {

        /**
         * Background.
         */

        background-color: $brand-one;

        /**
         * Display.
         */

        display: block;

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: 0;

        /**
         * Z-Index.
         */

        z-index: 10;

        /**
         * Sizing.
         */

        width: 6rem;
        height: 6rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            width: 3rem;
            height: 3rem;

        }

        /**
         * .close-icon.
         */

        .close-icon {

            width: 1.5rem;

            position: absolute;
            top: 50%;
            left: 50%;

            transform: translateX(-50%) translateY(-50%);

        }

        /**
         * &:hover.
         */

        &:hover {

            /**
             * 
             */

            background-color: white;

            /**
             * svg.
             */

            svg {

                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * Fill.
                     */

                    fill: $brand-two !important;

                }

            }

        }

    }

    /**
     * .dismiss-flyout.
     */

    .dismiss-flyout {

        /**
         * Display.
         */

        display: block;

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        left: 0;

        /**
         * Sizing.
         */
    
        width: 100%;
        height: 100%;

    }

    /**
     * .contact-flyout-container.
     */

    .contact-flyout-container {

        /**
         * Display.
         */
    
        display: block;

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: 0;

        /**
         * Sizing.
         */

        width: 50%;
        height: 100vh;

        /**
         * Transform.
         */

        transform: translateX(110%);

        /**
         * Transition.
         */

        transition: transform 0.3s ease-out;

        /**
         * Shadow.
         */

        -webkit-box-shadow: -3px 0px 5px 0px rgba(142, 81, 81, 0.15);
        -moz-box-shadow: -3px 0px 5px 0px rgba(0,0,0,0.15);
        box-shadow: -3px 0px 5px 0px rgba(0,0,0,0.15);

        /**
         * @include.
         */

        @include breakpoint($up-to-desktop-exclusive) {

            width: 75%;

        }

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            width: 100%;

        }

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * @include.
         */

        @include breakpoint($go-fluid-width) {

            /**
             * 
             */

            // background-color: orange !important;

        }

        /**
         * Font related.
         */

        color: white;

        /**
         * Sizing.
         */

        height: 100%;

        /**
         * Flex.
         */

        flex-direction: column;

        /**
         * .wpforms-container.
         */

        .wpforms-container {

            /**
             * label.wpforms-error.
             */

            label.wpforms-error {

                /**
                 * Padding.
                 */

                padding-top: 0.5rem;

                /**
                 * Font related.
                 */

                font-size: 1rem;
                font-weight: bold;
                color: $brand-one;

            }

            /**
             * input.
             */

            input, textarea {

                /**
                 * Border.
                 */

                border-color: white;
                border: 0 !important;
                border-bottom: 1px solid rgba(255,255,255,0.4) !important;

                /**
                 * Background.
                 */

                background-color: rgba(255,255,255,0) !important;
                
                /**
                 * Sizing.
                 */
                
                width: 100% !important;
                max-width: 100% !important;

                /**
                 * Font related.
                 */

                color: white !important;

                /**
                 * Padding.
                 */

                padding-left: 0 !important;

                /**
                 * &::placeholder.
                 */

                &::placeholder {

                    /**
                     * Font related.
                     */

                    color: rbga(255,255,255,0.8);

                }

            }

            /**
             * textarea.
             */

            textarea {

                /**
                 * Margin.
                 */

                margin-bottom: 0.5rem;

                /**
                 * Sizing.
                 */

                height: 90px !important;

            }

            /**
             * input[type="checkbox"].
             */

            input[type="checkbox"] {

                /**
                 * Sizing.
                 */

                width: auto !important;                

            }

            /**
             * .wpforms-field-checkbox.
             */

            .wpforms-field-checkbox {

                /**
                 * label.
                 */

                label {

                    /**
                     * Font related.
                     */

                    font-size: 0.9rem;
                    color: #666 !important;

                }

            }

            /**
             * button.
             */

            button {

                /**
                 * Background.
                 */

                background-color: $brand-one !important;

                /**
                 * Border.
                 */

                border: 0 !important;

                /**
                 * Font related.
                 */

                color: white !important;

                /**
                 * Padding.
                 */

                padding-left: 1.5rem !important;
                padding-right: 1.5rem !important;

                /**
                 * &:hover.
                 */

                &:hover {

                    background-color: white !important;

                    color: $brand-two !important;

                }

            }

        }

        /**
         * .contact-list.
         */

        .contact-list {

            /**
             * 
             */

            margin-bottom: 1rem;

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: block;

                /**
                 * Font related.
                 */

                font-size: 1.15rem;
                line-height: 1.5rem;

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    font-size: 1.15rem;
                    color: $brand-four;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        text-decoration: none;
                        color: $brand-five;

                    }

                }

            }

        }

        /**
         * .address-list.
         */

        .address-list {

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Font related.
                 */

                line-height: 1.5rem;

                /**
                 * &:last-child.
                 */

                &:last-child {

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Content.
                         */

                        content: '';

                    }

                }

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * 
                     */

                    content: ',';

                }

            } 

        }

        /**
         * .part-a.
         */

        .part-a {

            /**
             * Position.
             */

            position: absolute;
            top: 0;

            /**
             * Sizing.
             */

            width: 100%;
            // height: 50%;

            /**
             * Padding.
             */

            padding-left: 3rem;
            padding-right: 3rem;
            padding-top: 6rem;
            padding-bottom: 2rem;

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                padding-top: 2rem;
                padding-left: 1rem;
                padding-right: 1rem;

            }

            /**
             * @include.
             */

            @include breakpoint($go-fluid-width) {

                /**
                 * 
                 */

                position: relative;
                top: auto;

            }

            /**
             * 
             */

            background-color: black;

        }

        /**
         * .part-b.
         */
    
        .part-b {

            /**
             * Position.
             */

            position: absolute;
            bottom: 0;

            /**
             * Sizing.
             */

            height: 20%;
            width: 100%;

            /**
             * @include.
             */

            @include breakpoint($go-fluid-width) {

                /**
                 * Position related.
                 */

                position: relative;
                bottom: auto;

                /**
                 * Sizing.
                 */

                height: 20rem !important;

            }

        }

        /**
         * #map.
         */

        #map {

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 0;
            
            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;

        }

    }

}