/**
 * razor-edge-top-footer.
 */

@mixin razor-edge-top-footer {

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Position.
     */

    position: absolute;
    top: 0;
    left: 0;

    /**
     * Sizing.
     */

    height: 100%;
    width: 100%;        

    /**
     * Transform.
     */

    transform: translateY(-50%);

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * Padding.
         */

        transform: translateY(-25%);

    }

    /**
     * &::before.
     */

    &::before {

        /**
         * Content.
         */

        content: '';

        /**
         * Position related.
         */

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /**
         * Z-index.
         */

        z-index: 1;

        /**
         * Sizing.
         */

        width: 100%;
        height: 100%;

        /**
         * Transform.
         */

        transform-origin: top left;
        transform: skewY(12deg);

        /**
         * breakpoint.
         */

        @include breakpoint($shallower-angle) {

            /**
             * Transform.
             */

            transform: skewY(#{12deg - 4});

        }

        /**
         * Background.
         */

        background-color: $brand-two;

    }

}

/**
 * razor-edge-fill-in-footer.
 */

@mixin razor-edge-fill-in-footer--after {

    /**
     * &::after.
     */

    &::after {

        /**
         * Content.
         */

        content: '';

        /**
         * Position.
         */

        position: absolute;
        bottom: 0;
        left: 0;

        /**
         * Z-index.
         */

        z-index: -2;

        /**
         * Sizing.
         */

        width: 100%;
        height: 52%;

        /**
         * 
         */

        background-color: $brand-two;

    }

}

/**
 * razor-edge-top-and-fill-in.
 */

@mixin razor-edge-top-and-fill-in(
    $razorColor: pink,
    $razorHeight: 100%,
    $razorTransformDeg: 12deg,
    $razorTranslateY: -30%,
    $fillHeight: 40%,
    $renderFillIn: true
) {

    /**
     * &::before.
     */

    &::before {

        /**
         * Content.
         */

        content: '';

        /**
         * Position related.
         */

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        /**
         * Z-index.
         */

        z-index: -1;

        /**
         * Sizing.
         */

        width: 100%;
        height: #{$razorHeight};

        /**
         * Transform.
         */

        transform-origin: top left;
        transform: skewY(#{$razorTransformDeg}) translateY(#{$razorTranslateY});

        /**
         * breakpoint.
         */

        @include breakpoint($shallower-angle) {

            /**
             * Transform.
             */

            transform: skewY(#{$razorTransformDeg - 4}) translateY(#{$razorTranslateY});

        }

        /**
         * Background.
         */

        background-color: #{$razorColor};

    }

    /**
     * 
     */

    @if $renderFillIn == true {

        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * Position.
             */

            position: absolute;
            bottom: 0;
            left: 0;

            /**
             * Z-index.
             */

            z-index: -2;

            /**
             * Sizing.
             */

            width: 100%;
            height: #{$fillHeight};

            /**
             * Background.
             */

            background-color: #{$razorColor};

        }

    }

}

/**
 * razor-edge-top-for-image-masking.
 */

@mixin razor-edge-top-for-image-masking(
    $razorColor: springgreen,
    $razorHeight: 20rem,
    $razorDeg: 12deg
) {

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Position.
     */

    position: absolute;
    top: 0;
    left: 0;

    /**
     * Sizing.
     */

    height: #{$razorHeight + 2rem};
    width: 100%;        

    /**
     * &::before.
     */

    &::before {

        /**
         * Content.
         */

        content: '';

        /**
         * Position related.
         */

        position: absolute;
        bottom: 0;
        right: 0;

        /**
         * Z-index.
         */

        z-index: 1;

        /**
         * Sizing.
         */

        width: 120%;
        height: #{$razorHeight * 2};

        /**
         * Transform.
         */

        transform-origin: bottom right;
        transform: rotate(#{$razorDeg});

        /**
         * breakpoint.
         */

        @include breakpoint($shallower-angle) {

            /**
             * Transform.
             */

            transform: rotate(#{$razorDeg - 4});

        }

        /**
         * Background.
         */

        background-color: #{$razorColor};

    }

}