/**
 * .common-intro-wrapper.
 */

.common-intro-wrapper {

    /**
     * Background.
     */

    background-color: $brand-six;

    /**
     * Padding.
     */

    padding-bottom: 3rem;

    /**
     * .common-intro-container.
     */

    .common-intro-container {

    }

}