/**
 * Body.
 */

body {

    /**
     * Padding.
     */

    padding-top: 6rem;

    /**
     * Font related.
     */

    font-family: 'Open Sans', sans-serif;

    /**
     * p.
     */

    p {

        /**
         * Margin.
         */

        margin-bottom: 1.5rem;

        /**
         * Font related.
         */

        font-size: 1.125rem;
        line-height: 1.7rem;

    }

    h1 {

        letter-spacing: 13px;

        font-weight: 700;

        -webkit-font-variant-ligatures: no-common-ligatures;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            font-size: 2.2rem;

            letter-spacing: 8px;

            font-variant-ligatures: no-common-ligatures;
            -webkit-font-variant-ligatures: no-common-ligatures;

        }

    }

    h2 {

        letter-spacing: 9px;

        font-weight: 700;

        font-variant-ligatures: no-common-ligatures;
        -webkit-font-variant-ligatures: no-common-ligatures;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            font-size: 1.8rem;

            letter-spacing: 6px;

        }

    }

    /**
     * h3.
     */

    h3 {

        /**
         * Font related.
         */

        font-weight: 700;

        font-variant-ligatures: no-common-ligatures;
        -webkit-font-variant-ligatures: no-common-ligatures;

    }

    /**
     * h5.
     */

    h5 {

        /**
         * Font related.
         */

        font-weight: 700;

        font-size: 1.8rem;

        letter-spacing: 5px;

        font-variant-ligatures: no-common-ligatures;
        -webkit-font-variant-ligatures: no-common-ligatures;

        margin-bottom: 1rem;

    }

    /**
     * 
     */

    .example {

        h1 {
            color: magenta !important
        }        

    }

    /**
     * .page-transition-logo.
     */

    .page-transition-logo {

        /**
         * Position.
         */

        position: fixed;
        top: 50%;
        left: 50%;

        /**
         * Z-index.
         */

        z-index: 100;

        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-50%);

        /**
         * Sizing.
         */

        width: 15rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            width: 10rem;

        }

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Transition.
         */

        transition: opacity 0.25s ease-out;

        /**
         * Pointer events.
         */

        pointer-events: none;

    }

    /**
     * .page-transition-razor-edge.
     */

    .page-transition-razor-edge {

        /**
         * Position related.
         */

        position: fixed;
        bottom: 0;
        left: 0;

        /**
         * Z-index.
         */

        z-index: 50;

        /**
         * Sizing.
         */

        width: 100%;
        height: calc(100% + 50rem);

        /**
         * Overflow.
         */

        overflow: hidden;

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Transform.
         */

        transform: translateY(100%);

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            white,
            50rem,
            12deg,
            0,
            80%,
            true
        );

    }

    /**
     * .covid-bar.
     */

    .covid-bar {

        /**
         * .covid-popup-content.
         */

        .covid-popup-content {

            /**
             * Display.
             */

            display: none;

        }

    }

}