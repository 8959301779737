// Remember: do NOT edit this file.

:root {
    font-size: 16px;
}

html ul li a {
    font-size: 1rem; // 16px
}

html p {
    font-size: 1rem; // 16px
}

html h1 {
    font-size: 5.8125rem; // 93px
}

.heading-large {
    font-size: 2.25rem; // 36px
}

.sub-heading {
    font-size: 1.75rem; // 28px
}

html h2 {
    font-size: 3.1875rem; // 51px
}

html h3 {
    font-size: 2.25rem; // 36px
}

html h4 {
    font-size: 2.25rem; // 36px
}

html h5 {
    font-size: 2.25rem; // 36px
}

html ul li {
    font-size: 1rem; // 16px
}
