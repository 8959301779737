/**
 * .home-intro-wrapper.
 */

.home-intro-wrapper {

    /**
     * Margin.
     */

    margin-top: -10rem;

    /**
     * Position.
     */

    position: relative;
    
    /**
     * Z-index.
     */

    z-index: 1;

    /**
     * @include.
     */

    @include razor-edge-top-and-fill-in(
        $brand-one
    );

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            $brand-one,
            100%,
            12deg,
            -5rem
        );

    }

    /**
     * .home-intro-container.
     */

    .home-intro-container {
   
        /**
         * Font related.
         */

        text-align: center;
        color: white;

        /**
         * Padding.
         */

        padding-top: 5rem;
        padding-bottom: 3rem;

        /**
         * .text-wrapper.
         */

        .text-wrapper {

            /**
             * Padding.
             */

            padding-top: 4rem;
            padding-bottom: 4rem;

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                font-size: 1.5625rem;
                line-height: 2.5rem;

            }

        }

        /**
         * .main-graphic.
         */

        .main-graphic {

            max-width: 80%;

        }

        /**
         * .link.
         */

        .link {

            /**
             * Font related.
             */

            font-size: 1.75rem;
            color: white;
            text-decoration: none;

            /**
             * Display.
             */
        
            display: inline-block;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Font related.
                 */

                color: $brand-two;

                /**
                 * .graphic.
                 */

                .graphic {

                    /**
                     * polyline.
                     */

                    polyline {

                        /**
                         * Stroke.
                         */

                        stroke: $brand-two !important;

                    }

                }

            }

            /**
             * .link-contents.
             */

            .link-contents {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                align-items: center;

                /**
                 * .graphic.
                 */

                .graphic {

                    /**
                     * Margin.
                     */

                    margin-left: 1rem;

                    /**
                     * Sizing.
                     */

                    width: 1.25rem;

                    /**
                     * 
                     */

                    polyline {

                        stroke: white;

                    }

                }

            }

        }

    }

}