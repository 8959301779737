/**
 * .about-testimonials-wrapper.
 */

.about-testimonials-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * 
     */

    z-index: 10;

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 3rem;

    /**
     * Margin.
     */

    margin-top: -8rem;

    /**
     * @include.
     */

    @include razor-edge-top-and-fill-in(
        $brand-six
    );

    /**
     * @include.
     */

    @include breakpoint($whilst-fluid) {

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            $brand-six,
            50%,
            12deg,
            -5rem,
            70%
        );

    }
    
    /**
     * .about-testimonials-container.
     */

    .about-testimonials-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Padding.
         */

        padding-bottom: 17rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Padding.
             */

            padding-bottom: 14rem

        }
        
        /**
         * h2.
         */

        h2 {

            /**
             * Margin.
             */

            margin-bottom: 3rem;

        }

        /**
         * .graphic.
         */

        .graphic {

            /**
             * Position related.
             */

            position: absolute;
            top: 0;
            left: 0;

            /**
             * Sizing.
             */

            width: 3.5rem;

        }

        /**
         * .testimonials-swiper-container
         */

        .testimonials-swiper-container {

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * .swiper-slide.
             */

            .swiper-slide {

                /**
                 * Padding.
                 */

                padding-right: 30%;
                padding-left: 4.5rem;

                /**
                 * @include.
                 */

                @include breakpoint($whilst-fluid) {

                    padding-right: 0;

                }

            }

            /**
             * .graphic.
             */

            .graphic {

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: $brand-one;

                }

            }

        }

    }

}