/**
 * .home-hero-wrapper.
 */

.home-hero-wrapper {

    /**
     * Sizing.
     */

    height: 50rem;

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * 
         */

        height: 40rem;

    }

    /**
     * Background.
     */

    background-position: center;
    background-size: cover;

    /**
     * .home-hero-container.
     */

    .home-hero-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Display.
         */

        display: block;

        /**
         * Sizing.
         */

        height: 100%;
        
        /**
         * .text-lines.
         */

        .text-lines {

            /**
             * Display.
             */

            display: block;

            /**
             * Position.
             */

            position: absolute;
            top: 18%;
            left: 50%;

            /**
             * Transform.
             */

            transform: translateX(-50%);

            /**
             * Font related.
             */

            text-align: center;

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                font-size: 4rem;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 0.5rem;
                color: black;

                /**
                 * Shadow.
                 */

                // text-shadow: 2px 2px 2px rgba(#C3C3C3, 0.5);

                /**
                 * @include.
                 */

                @include breakpoint($up-to-ipad-exclusive) {

                    /**
                     * Font related.
                     */

                    font-size: 2rem;

                }
                
                /**
                 * 
                 */

                margin-bottom: 1rem;

            }           

        }

    }

}