/**
 * .common-hero-wrapper.
 */

.common-hero-wrapper {

    /**
     * Background.
     */

    background-color: $brand-six;

    /**
     * Padding.
     */

    padding-top: 7rem;
    padding-bottom: 3rem;

    /**
     * .common-hero-container.
     */

    .common-hero-container {

    }

}