/**
 * footer.
 */

footer {

    /**
     * Font related.
     */

    color: white;

    /**
     * Margin.
     */

    margin-top: -8rem;

    /**
     * Position.
     */

    position: relative;

    /**
     * Z-index.
     */

    z-index: 10;
    
    /**
     * .razor-edge.
     */

    .razor-edge {

        /**
         * @include.
         */

        @include razor-edge-top-footer();

    }

    /**
     * @include.
     */

    @include razor-edge-fill-in-footer--after();

    /**
     * .container.
     */

    .container {

        overflow: hidden;
        
    }

    /**
     * .row-one.
     */

    .row-one {

        /**
         * 
         */

        .logo {

            width: 7rem;

        }

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            font-size: 0.8rem;
            color: $brand-five;

            /**
             * Margin.
             */

            margin-bottom: 0.5rem;

        }

        /**
         * ul.list.
         */

        ul.list {

            /**
             * Margin.
             */

            margin-bottom: 1rem;

            /**
             * li.list-item.
             */

            li.list-item {

                /**
                 * Font related.
                 */

                font-size: 0.8rem;

                /**
                 * Margin.
                 */

                margin-bottom: 0.2rem;

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    font-size: 0.8rem;
                    color: $brand-four;                   
                    line-height: 1rem;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        text-decoration: none;
                        color: $brand-five;

                    }

                }

            }

        }

    }

    /**
     * .row-two.
     */

    .row-two {

        /**
         * Padding.
         */

        padding-top: 3rem;
        padding-bottom: 3rem;

        /**
         * @include.
         */

        @include breakpoint($whilst-fluid) {

            /**
             * Padding.
             */

            padding-bottom: 0rem;

        }

        /**
         * p.
         */

        p {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Font related.
             */

            font-size: 0.8rem;
            color: $brand-five;

        }

        /**
         * .list
         */

        .list {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * .list-item.
             */

            .list-item {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * 
                 */

                &:before {

                    /**
                     * Content.
                     */

                    content: '|';

                    /**
                     * Font related.
                     */

                    font-size: 0.8rem;
                    color: $brand-five;

                }

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    font-size: 0.8rem;
                    color: $brand-five;
                    
                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        color: white;
                        text-decoration: none;

                    }

                }

            }

        }

    }

}