// Remember: do NOT edit this file.

$brand-four: #FFFFFF;

$brand-five: #636363;

$brand-six: #ebebeb;

$brand-three: #d4d3d3;

$brand-two: #000;

$brand-one: #bf0011;
