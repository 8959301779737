/**
 * .ourreach-info-wrapper.
 */

.ourreach-info-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Z-index.
     */

    z-index: 5;

    /**
     * Padding.
     */

    padding-bottom: 6rem;

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * @include.
     */

    @include razor-edge-top-and-fill-in(
        $brand-one,
        100%,
        12deg,
        -19rem,
        40%
    );

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            $brand-one,
            100%,
            12deg,
            -11rem,
            40%
        );

    }

    /**
     * .ourreach-info-container.
     */

    .ourreach-info-container {

        /**
         * Padding.
         */

        padding-bottom: 19rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Padding.
             */

            padding-bottom: 15rem;

        }

        /**
         * .row-text.
         */

        .row-text {

            /**
             * Padding.
             */

            padding-left: 6rem;
            padding-right: 6rem;
            padding-bottom: 2rem;

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                /**
                 * Padding.
                 */

                padding-left: 0;
                padding-right: 0;

            }

            /**
             * .link.
             */

            .link {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Margin.
                 */

                margin-top: 2rem;

                /**
                 * Font related.
                 */

                font-weight: bold;
                font-size: 1.25rem;
                color: $brand-four;
                text-decoration: none;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

            }

        }

        /**
         * h2.heading.
         */

        h2.heading {

            margin-bottom: 2rem;

        }

        /**
         * Font related.
         */

        color: white;

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * &:nth-child(even).
                 */                

                &:nth-child(even) {

                    flex-direction: row-reverse;

                    /**
                     * .read-more-container.
                     */

                    .read-more-container {

                        /**
                         * Position.
                         */

                        left: auto !important;
                        right: 0;

                    }

                }

                /**
                 * @include.
                 */

                @include breakpoint($up-to-ipad-exclusive) {

                    display: block;

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Sizing.
                     */

                    min-height: 24rem;

                    /**
                     * @include.
                     */

                    @include breakpoint($up-to-ipad-exclusive) {

                        /**
                         * Sizing.
                         */

                        min-height: 20rem;

                    }

                    /**
                     * Flex.
                     */

                    flex-basis: 50%;

                    /**
                     * Background.
                     */

                    background-size: cover;
                    background-position: center;

                    /**
                     * img.
                     */

                    img {

                        display: none;

                    }

                }

                /**
                 * .text-container.
                 */

                .text-container {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Font related.
                     */
                
                    text-align: center;

                    /**
                     * @include.
                     */

                    @include breakpoint($up-to-ipad-exclusive) {

                        /**
                         * Sizing.
                         */

                        min-height: 20rem;

                    }

                    /**
                     * &.show.
                     */

                    &.show {

                        /**
                         * .details-container.
                         */

                        .details-container {

                            /**
                             * Transform.
                             */

                            transform: translateY(0);

                        }

                        /**
                         * .read-more-container.
                         */

                        .read-more-container {

                            /**
                             * .plus.
                             */

                            .plus {

                                /**
                                 * Display.
                                 */

                                display: none;

                            }

                            /**
                             * .minus.
                             */

                            .minus {

                                /**
                                 * Display.
                                 */

                                display: block;

                            }

                        }

                    }

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Overflow.
                     */
                
                    overflow: hidden;

                    /**
                     * Flex.
                     */

                    flex-basis: 50%;
                    align-items: center;
                    justify-content: center;

                    /**
                     * Background.
                     */

                    background-color: white;

                    /**
                     * .heading.
                     */

                    .heading {

                        color: $brand-two;

                    }

                    /**
                     * .minus.
                     */

                    .minus {

                        /**
                         * Display.
                         */

                        display: none;

                    }

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        text-decoration: none;

                        /**
                         * 
                         */

                        h3 {

                            color: $brand-five;

                        }

                        /**
                         * .read-more-container.
                         */

                        .read-more-container {

                        }

                    }

                    /**
                     * .read-more-container.
                     */

                    .read-more-container {

                        /**
                         * Background.
                         */

                        background-color: $brand-two;

                        /**
                         * Position.
                         */

                        position: absolute;
                        top: 0;
                        left: 0;

                        /**
                         * Z-index.
                         */

                        z-index: 10;

                        /**
                         * @include.
                         */

                        @include breakpoint($up-to-ipad-exclusive) {

                            /**
                             * Position related..
                             */

                            left: auto !important;
                            right: 0 !important;


                        }
                        
                        /**
                         * Padding.
                         */

                        width: 3rem;
                        height: 3rem;

                        /**
                         * .image.
                         */

                        .image {

                            /**
                             * Position related.
                             */

                            position: absolute;
                            top: 50%;
                            left: 50%;

                            /**
                             * Transform.
                             */

                            transform: translateX(-50%) translateY(-50%);

                            /**
                             * 
                             */
                        
                            width: 1.2rem;

                        }

                        svg {

                            polygon, rect {

                                fill: white;

                            }

                        }

                    }

                    /**
                     * .details-container.
                     */

                    .details-container {

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Position related.
                         */

                        position: absolute;
                        top: 0;
                        left: 0;

                        /**
                         * Sizing.
                         */

                        width: 100%;
                        height: 100%;

                        /**
                         * Background.
                         */

                        background-color: $brand-two;

                        /**
                         * Transform.
                         */

                        transform: translateY(100%);

                        /**
                         * Transition.
                         */

                        transition: transform 0.25s ease-out;

                        /**
                         * Font related.
                         */
                    
                        text-align: left;

                        /**
                         * ul.
                         */

                        ul {

                            /**
                             * Position related.
                             */

                            position: absolute;
                            top: 50%;
                            left: 4rem;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                            /**
                             * 
                             */

                            list-style: disc !important;

                            /**
                             * li.
                             */

                            li {

                                /**
                                 * 
                                 */

                                display: list-item;

                                /**
                                 * Flex.
                                 */


                                /**
                                 * Margin.
                                 */

                                margin-bottom: 1rem;

                                /**
                                 * Font related.
                                 */

                                font-size: 1.6rem;
                                line-height: 2rem;
                                color: white;

                                /**
                                 * &:last-child.
                                 */

                                &:last-child {

                                    margin-bottom: 0;

                                }                                

                            }

                        }

                    }

                }

            }

        }

    }

}