/**
 * .shipping-infoblock-wrapper.
 */

.shipping-infoblock-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Z-index.
     */

    z-index: 5;

    /**
     * Background.
     */

    background-position: center;
    background-size: cover;

    /**
     * Padding.
     */

    padding-bottom: 38rem;
    padding-top: 6rem;

    /**
     * @include.
     */

    @include breakpoint($whilst-fluid) {

        /**
         * Background.
         */

        background-image: none !important;
        background-color: $brand-one;

        /**
         * Padding.
         */

        padding-bottom: 16rem;

    }

    /**
     * .razor-edge.
     */

    .razor-edge {

        @include razor-edge-top-for-image-masking(
            $brand-six
        );

    }

    /**
     * .shipping-infoblock-container.
     */

    .shipping-infoblock-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Margin.
         */

        margin-top: -6rem;

        /**
         * Z-index.
         */

        z-index: 5;

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            $brand-one,
            50%,
            12deg,
            0,
            50%
        );

        /**
         * Font related.
         */

        color: white;

        /**
         * Padding.
         */

        padding-left: 6rem;
        padding-right: 6rem;
        padding-bottom: 2rem;
        padding-top: 2rem;
        
        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Padding.
             */

            padding-left: 1rem;
            padding-right: 1rem;

        }

        /**
         * .heading,
         */

        .heading {

            /**
             * Margin.
             */

            margin-bottom: 4rem;

            /**
             * Padding.
             */

            padding-top: 10rem;

        }

        /**
         * ul.
         */

        ul {

            li {

                /**
                 * Margin.
                 */

                margin-bottom: 3rem;

                /**
                 * .list-item-content.
                 */

                .list-item-content {

                    /**
                     * .col-image.
                     */

                    .col-image {

                        /**
                         * .image-container.
                         */

                        .image-container {

                            /**
                             * Sizing.
                             */

                            height: 16rem;

                            /**
                             * Background.
                             */

                            background-size: cover;
                            background-position: center;

                            /**
                             * img.
                             */

                            img {
                                display: none;
                            }

                        }

                    }

                    /**
                     * .col-text.
                     */

                    .col-text {

                        /**
                         * Display.
                         */

                        display: flex;

                        /**
                         * Padding.
                         */

                        padding-left: 3rem;
                        padding-right: 3rem;

                        /**
                         * @include.
                         */

                        @include breakpoint($up-to-ipad-exclusive) {

                            /**
                             * Padding.
                             */

                            padding-top: 2rem;
                            padding-left: 1rem;
                            padding-right: 1rem;

                        }

                        /**
                         * Flex.
                         */

                        align-items: center;

                        /**
                         * p.
                         */

                        p {

                            &:last-child {
                                
                                margin-bottom: 0;

                            }

                        }

                    }

                }

            }

        }

    }

}