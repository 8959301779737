/**
 * .simple-popup-wrapper.
 */

.simple-popup-wrapper {

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    left: 0;

    /**
     * Sizing.
     */

    width: 100%;
    height: 0;

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Z-index.
     */

    z-index: 20;

    /**
     * Background.
     */

    background-color: rgba($brand-two, 0.95);

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * Pointer events.
     */

    pointer-events: none;

    /**
     * &.show.
     */

    &.show {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer events.
         */

        pointer-events: all;

        /**
         * Sizing.
         */

        height: 100%;

        /**
         * Overflow.
         */

        overflow: hidden;

    }

    /**
     * Transition.
     */

    transition: opacity 0.3s ease;

    /**
     * 
     */

    overflow: auto;

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-inclusive) {

        /**
         * Display.
         */

        display: block;

    }

    /**
     * .close-popup.
     */

    .close-popup {

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: 0;

        /**
         * Sizing.
         */

        width: 6rem;
        height: 6rem;

        /**
         * Background.
         */

        background-color: $brand-one;

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: center;
        align-items: center;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Sizing.
             */

            width: 3rem;
            height: 3rem;

        }

        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            width: 1.5rem;
            height: 1.5rem;

        }

        /**
         * svg.
         */

        svg {

            /**
             * Sizing.
             */

            width: 1.5rem;
            height: 1.5rem;

            /**
             * 
             */

            pointer-events: none;

        }

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Background.
             */

            background-color: white;

            /**
             * svg.
             */

            svg {

                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * Fill.
                     */

                    fill: $brand-two;

                }

            }

        }

    }

    /**
     * .simple-popup.
     */

    .simple-popup {

        /**
         * Margin.
         */

        margin-left: 4rem;
        margin-right: 14rem;

        /**
         * Font related.
         */

        color: white;

        /**
         * Padding.
         */

        padding-top: 10rem;;
        padding-bottom: 2rem;
        
        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-inclusive) {

            /**
             * Padding.
             */

            padding-top: 8rem;

            /**
             * Margin.
             */

            margin-left: 2rem;
            margin-right: 2rem;

            /**
             * Transform.
             */

            transform: none;

        }

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad-exclusive) {

            /**
             * Padding.
             */

            padding-top: 4rem;

            /**
             * Margin.
             */

            margin-left: 1rem;
            margin-right: 1rem;

        }

        /**
         * p.heading.
         */
    
        p.heading {

            /**
             * Font related.
             */

            font-size: 3.2rem;
            line-height: 3.8rem;
            letter-spacing: 13px;
            font-weight: 700;   
            -webkit-font-variant-ligatures: no-common-ligatures;

            /**
             * Margin.
             */

            margin-bottom: 3rem;

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                /**
                 * Font related.
                 */

                font-size: 2.5rem;
                line-height: 3.0rem;
                letter-spacing: 11px;

            }

        }

        /**
         * p a.
         */

        p a {

            /**
             * Font related.
             */

            color: $brand-four;
            text-decoration: underline;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Font related.
                 */

                color: $brand-one;

            }

        }

        /**
         * ul.
         */

        ul {

            /**
             * Margin.
             */

            margin-left: 1.3rem;

            /**
             * li.
             */

            li {

                /**
                 * List style.
                 */

                list-style: disc;

                /**
                 * Font related.
                 */

                font-size: 1.125rem;
                line-height: 1.5rem;

                /**
                 * Margin.
                 */

                margin-bottom: 0.5rem;

            }

        }

    }

}