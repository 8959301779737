/**
 * .products-products-wrapper.
 */

.products-products-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Z-index.
     */

    z-index: 5;

    /**
     * @include.
     */

    @include razor-edge-top-and-fill-in(
        $brand-one,
        100%,
        12deg,
        -17rem,
        40%
    );

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * @include.
         */

        @include razor-edge-top-and-fill-in(
            $brand-one,
            100%,
            12deg,
            -11rem,
            40%
        );

    }

    /**
     * Margin.
     */

    margin-top: -1rem;

    /**
     * Padding.
     */

    padding-bottom: 22rem;

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad-exclusive) {

        /**
         * Padding.
         */

        padding-bottom: 16rem;

    }

    /**
     * .products-products-container.
     */

    .products-products-container {
        
        /**
         * .row-product-groups.
         */

        .row-product-groups {

            /**
             * Font related.
             */

            color: $brand-four;

        }

        /**
         * .row-products.
         */

        .row-products {

            /**
             * Margin.
             */

            margin-top: 3.5rem;

        }

        /**
         * h2.
         */

        h2 {

            /**
             * Margin.
             */

            margin-bottom: 2rem;

            /**
             * @include.
             */

            @include breakpoint($up-to-ipad-exclusive) {

                /**
                 * Font related.
                 */

                margin-bottom: 1rem;

            }

        }

        /**
         * .products-list.
         */

        .products-list {

            /**
             * Margin.
             */

            margin-bottom: 3rem;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Overflow.
                 */

                overflow: hidden;

                /**
                 * Margin.
                 */

                margin-bottom: 1rem;
                
            }

            /**
             * .product-wrapper.
             */

            .product-wrapper {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Sizing.
                 */

                width: 100%;               

                /**
                 * Font related.
                 */

                text-decoration: none;

                /**
                 * @include.
                 */

                @include breakpoint($up-to-ipad-inclusive) {

                    /**
                     * Flex.
                     */

                    display: block;

                    /**
                     * .image-container.
                     */

                    .image-container {

                        /**
                         * Sizing.
                         */

                        width: 100%;
                        height: 16rem;

                    }

                    /**
                     * .text-container.
                     */

                    .text-container {

                        /**
                         * Sizing.
                         */

                        width: 100%;

                        /**
                         * Padding.
                         */
                    
                        padding-left: 2rem;
                        padding-top: 4rem;
                        padding-bottom: 4rem;
                        padding-right: 2rem;

                    }

                }
                
                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * .text-container.
                     */

                    .text-container {

                        /**
                         * Background.
                         */

                        background-color: $brand-two;

                        /**
                         * Font related.
                         */

                        color: $brand-four;
                        text-decoration: none;

                    }

                }

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Background.
                 */

                background-position: center;
                background-size: cover;

                /**
                 * Sizing.
                 */

                width: 50%;

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

            }

            /**
             * .text-container.
             */

            .text-container {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                align-items: center;

                /**
                 * Padding.
                 */

                padding-left: 3rem;
                padding-top: 8rem;
                padding-bottom: 8rem;
                padding-right: 2rem;

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Sizing.
                 */

                width: 50%;

                /**
                 * Font related.
                 */

                color: $brand-two;
                text-decoration: none;
                
                /**
                 * Background.
                 */

                background-color: $brand-four;

                /**
                 * .heading.
                 */
                
                .heading {

                    /**
                     * Font related.
                     */

                    font-size: 2rem;
                    line-height: 2.7rem;

                    /**
                     * @include.
                     */

                    @include breakpoint($up-to-ipad-exclusive) {

                        /**
                         * Font related.
                         */

                        font-size: 1.8rem;
                        letter-spacing: 3px;
                        font-variant-ligatures: no-common-ligatures;
                        -webkit-font-variant-ligatures: no-common-ligatures;

                    }

                }

                /**
                 * .details-container.
                 */

                .details-container {

                    /**
                     * Display.
                     */

                    display: none;

                }

            }

            /**
             * .read-more-container.
             */

            .read-more-container {

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Position.
                 */

                position: absolute;
                top: 0;
                right: 0;

                /**
                 * Z-index.
                 */

                z-index: 10;

                /**
                 * @include.
                 */

                @include breakpoint($up-to-ipad-exclusive) {

                    /**
                     * Position related..
                     */

                    left: auto !important;
                    right: 0 !important;

                }
                
                /**
                 * Padding.
                 */

                width: 3rem;
                height: 3rem;

                /**
                 * .image.
                 */

                .image {

                    /**
                     * Position related.
                     */

                    position: absolute;
                    top: 50%;
                    left: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateX(-50%) translateY(-50%);

                    /**
                     * 
                     */
                
                    width: 1.2rem;

                }

                /**
                 * svg.
                 */

                svg {

                    /**
                     * polygon, rect.
                     */

                    polygon, rect {

                        /**
                         * Fill.
                         */

                        fill: white;

                    }

                }

            }

        }

    }

}