@font-face {
    font-family: 'fontello';

    src: url('../font/fontello/fontello.eot?53595380');
    src: url('../font/fontello/fontello.eot?53595380#iefix') format('embedded-opentype'),
         url('../font/fontello/fontello.woff?53595380') format('woff'),
         url('../font/fontello/fontello.ttf?53595380') format('truetype'),
         url('../font/fontello/fontello.svg?53595380#fontello') format('svg');

    font-weight: normal;
    font-style: normal;
}

.fontello {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* You can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

@mixin fontello() {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

