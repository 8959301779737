/**
 * .shipping-intro-wrapper.
 */

.shipping-intro-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * .shipping-intro-container.
     */

    .shipping-intro-container {

        padding-bottom: 2rem;

    }

}